import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "changing-staff-permissions--access-levels"
    }}>{`Changing Staff Permissions & Access Levels`}</h1>
    <p>{`There are three levels of staff permissions available, you can apply access levels to individuals dependent on their role and requirements. Note that it is only possible to manage staff permissions when your user has Admin permissions and your organisation is using the Sign in with Microsoft functionality. If you do not use this, please contact the support team to manage account access.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Admin level access permissions.</Alert>
    <h2 {...{
      "id": "create-staff-account"
    }}>{`Create Staff Account`}</h2>
    <p>{`It's possible to create accounts for your staff with ease, these accounts can be assigned access permissions individually.`}</p>
    <ol>
      <li parentName="ol">{`Click 'Advanced'`}</li>
      <li parentName="ol">{`Click 'Manage Staff Accounts'`}</li>
      <li parentName="ol">{`Click 'Create Staff Account'`}</li>
      <li parentName="ol">{`Provide a username, password and access level for the staff member`}</li>
      <li parentName="ol">{`Click 'Create User'`}</li>
    </ol>
    <h2 {...{
      "id": "manage-access-levels"
    }}>{`Manage Access Levels`}</h2>
    <p>{`To change Access Levels you will need to have Administrator access.`}</p>
    <ol>
      <li parentName="ol">{`Click 'Advanced'`}</li>
      <li parentName="ol">{`Click 'Manage Staff Accounts'`}</li>
      <li parentName="ol">{`Current Accounts will be listed`}</li>
      <li parentName="ol">{`To edit Access Level select the drop down menu under Access Level for the staff member you wish to edit`}</li>
      <li parentName="ol">{`Select a level`}</li>
      <li parentName="ol">{`Click 'Save'`}</li>
      <li parentName="ol">{`Unselecting the 'enable' tick box will immediately log out a staff member and will not allow access to the events manager`}</li>
    </ol>
    <h3 {...{
      "id": "staff-permissions"
    }}>{`Staff Permissions`}</h3>
    <ul>
      <li parentName="ul">{`Add Events`}</li>
      <li parentName="ul">{`Search for Events`}</li>
      <li parentName="ul">{`Manage Event Bookings`}</li>
    </ul>
    <h3 {...{
      "id": "manager-permissions"
    }}>{`Manager Permissions`}</h3>
    <ul>
      <li parentName="ul">{`All Staff Permissions`}</li>
      <li parentName="ul">{`Approve Events`}</li>
      <li parentName="ul">{`Edit Events`}</li>
      <li parentName="ul">{`Impersonate Members`}</li>
      <li parentName="ul">{`View Platform Statistics`}</li>
    </ul>
    <h3 {...{
      "id": "admin-permissions"
    }}>{`Admin Permissions`}</h3>
    <ul>
      <li parentName="ul">{`All Manager Permissions`}</li>
      <li parentName="ul">{`Manage User Permissions`}</li>
      <li parentName="ul">{`Manage Locations`}</li>
      <li parentName="ul">{`Manage API Keys`}</li>
      <li parentName="ul">{`Manage Advanced Platform Configuration`}</li>
    </ul>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/HcCWZmdY32I" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Use the Search Table function if you can't locate the staff member you're looking for. It is not possible to change your own permission level, another staff member must do this for you.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      